import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-grid-system"
import { graphql } from "gatsby"
import styled from "styled-components"
import Masonry from "react-masonry-css"
import "src/components/core/layout/masonry.css"
import Layout from "src/components/core/layout/layout"
import PhotoTile from "src/components/photos/photo-tile"
import SEO from 'src/components/seo/SEO';


export const query = graphql`
  query HomepageQuery {
    allPrismicProject(filter: {data: {gallery: {elemMatch: {homepage: {eq: "true"}}}}}) {
      nodes {
        uid
        data {
          name {
            text
          }
          date(difference: "now")
          gallery {
            homepage
            photo_date(difference: "now")
            model {
              text
            }
            photo {
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    }
  }
`

const shuffle = (array)=> {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

export default ({ data }) => {
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 3,
    500: 2,
  }
  const photos = []
  data.allPrismicProject.nodes.forEach(project =>
    project.data.gallery.forEach(photo => {
      if (photo.homepage == "true") {
        photos.push(
          <PhotoTile
            key={photo.photo}
            photo={photo.photo ? photo.photo.fluid.src : null}
            name={photo.model ? photo.model.text : null}
            project={project.data.name ? project.data.name.text : null}
            // link={project.uid}
            fluid={photo.photo.fluid}
          />
        )
    }
    })
  )
  shuffle(photos)
  return (
      <>
      <SEO/>
    <Layout>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {photos}
      </Masonry>
    </Layout>
    </>
  )
}
